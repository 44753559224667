import { RootState } from "../store";

export const sendingContactSelector = (state: RootState) =>
  state.email.sendingContact;

export const sendContactSuccessSelector = (state: RootState) =>
  state.email.sendContactSuccess;

export const sendContactErrorSelector = (state: RootState) =>
  state.email.sendContactError;
