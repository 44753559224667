import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Domain from "types/domain";
import GetSuggestionsResponse from "types/getAlternativesResponse";
import GetAvailableDomainsResponse from "types/getAvailableDomainsResponse";
import GetExpiringDomainsResponse from "types/getExpiringDomainsResponse";
import GetResellersResponse from "types/getResllersResponse";
import SearchResult from "types/searchResult";
import { hideWhoisModal } from "reducers/uiSlice";

interface DomainState {
  searchResult: SearchResult | undefined;
  searchingDomain: boolean;
  alternativeDomainExtensions: Array<SearchResult> | undefined;
  gettingAlternativeDomainExtensions: boolean;
  alternativeDomainExtensionsGottenForDomain: string | undefined;
  gettingExpiringDomains: boolean;
  expiringDomains: Array<Domain> | undefined;
  getExpiringDomainsError: string | undefined;
  gettingAvailableDomains: boolean;
  availableDomains: Array<Domain> | undefined;
  getAvailableDomainsError: string | undefined;
  resellers: Array<string> | undefined;
  gettingWhois: boolean;
  whois: SearchResult | undefined;
  getWhoisError: string | undefined;
}

const initialState: DomainState = {
  searchResult: undefined,
  searchingDomain: false,
  alternativeDomainExtensions: undefined,
  gettingAlternativeDomainExtensions: false,
  alternativeDomainExtensionsGottenForDomain: undefined,
  gettingExpiringDomains: false,
  expiringDomains: undefined,
  getExpiringDomainsError: undefined,
  gettingAvailableDomains: false,
  availableDomains: undefined,
  getAvailableDomainsError: undefined,
  resellers: undefined,
  gettingWhois: false,
  whois: undefined,
  getWhoisError: undefined,
};

const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    getWhois: (state, action: PayloadAction<string>) => {
      state.gettingWhois = true;
      state.getWhoisError = undefined;
      if (action.payload.length === 0) {
        state.whois = undefined;
      }
    },
    getWhoisSuccess: (
      state,
      action: PayloadAction<SearchResult | undefined>
    ) => {
      state.gettingWhois = false;
      state.whois = action.payload;
    },
    getWhoisFailure: (state, action: PayloadAction<string>) => {
      state.gettingWhois = false;
      state.getWhoisError = action.payload;
    },
    searchDomain: (state, action: PayloadAction<string>) => {
      state.searchingDomain = true;
      if (action.payload.length === 0) {
        state.searchResult = undefined;
        state.alternativeDomainExtensions = undefined;
        state.alternativeDomainExtensionsGottenForDomain = undefined;
      }
    },
    searchDomainSuccess: (
      state,
      action: PayloadAction<SearchResult | undefined>
    ) => {
      state.searchingDomain = false;
      state.searchResult = action.payload
        ? { ...action.payload, loading: false }
        : undefined;
      state.alternativeDomainExtensionsGottenForDomain = undefined;
      state.alternativeDomainExtensions = undefined;
    },
    searchDomainFailure: (state) => {
      state.searchingDomain = false;
    },
    searchAlternativeDomainExtension: (
      state,
      action: PayloadAction<string>
    ) => {
      if (!state.alternativeDomainExtensions) return;
      state.alternativeDomainExtensions.forEach((element) => {
        if (element.domain === action.payload) {
          element.loading = true;
        }
      });
    },
    searchAlternativeDomainExtensionSuccess: (
      state,
      action: PayloadAction<SearchResult>
    ) => {
      if (!state.alternativeDomainExtensions) return;
      state.alternativeDomainExtensions.forEach((element) => {
        if (element.domain === action.payload.domain) {
          element.loading = false;
          element.records = action.payload.records;
          element.registered = action.payload.registered;
        }
      });
    },
    searchAlternativeDomainExtensionFailure: (
      state,
      action: PayloadAction<string>
    ) => {},
    getAlternativeDomainExtensions: (state, action: PayloadAction<string>) => {
      state.gettingAlternativeDomainExtensions = true;
      state.alternativeDomainExtensions = undefined;
      state.alternativeDomainExtensionsGottenForDomain = action.payload;
    },
    getAlternativeDomainExtensionsSuccess: (
      state,
      action: PayloadAction<GetSuggestionsResponse>
    ) => {
      state.gettingAlternativeDomainExtensions = false;
      state.alternativeDomainExtensions = action.payload.results.map(
        (r) => ({ domain: r, loading: false } as SearchResult)
      );
    },
    getAlternativeDomainExtensionsFailure: (state) => {
      state.gettingAlternativeDomainExtensions = false;
    },
    getExpiringDomains: (state) => {
      state.gettingExpiringDomains = true;
      state.expiringDomains = undefined;
    },
    getExpiringDomainsSuccess: (
      state,
      action: PayloadAction<GetExpiringDomainsResponse>
    ) => {
      state.gettingExpiringDomains = false;
      state.expiringDomains = action.payload.results;
    },
    getExpiringDomainsFailure: (state, action: PayloadAction<string>) => {
      state.gettingExpiringDomains = false;
      state.getExpiringDomainsError = action.payload;
    },
    getAvailableDomains: (state) => {
      state.gettingAvailableDomains = true;
      state.availableDomains = undefined;
    },
    getAvailableDomainsSuccess: (
      state,
      action: PayloadAction<GetAvailableDomainsResponse>
    ) => {
      state.gettingAvailableDomains = false;
      state.availableDomains = action.payload.results;
    },
    getAvailableDomainsFailure: (state, action: PayloadAction<string>) => {
      state.gettingAvailableDomains = false;
      state.getAvailableDomainsError = action.payload;
    },
    getResellers: (state) => {
      state.resellers = undefined;
    },
    getResellersSuccess: (
      state,
      action: PayloadAction<GetResellersResponse>
    ) => {
      state.resellers = action.payload.resellers;
    },
  },
  extraReducers: {
    [hideWhoisModal.type]: (state) => {
      state.whois = undefined;
    },
  },
});

export const {
  searchDomainSuccess,
  searchDomainFailure,
  getAlternativeDomainExtensionsSuccess,
  getAlternativeDomainExtensionsFailure,
  searchAlternativeDomainExtensionSuccess,
  searchAlternativeDomainExtensionFailure,
  getExpiringDomainsSuccess,
  getExpiringDomainsFailure,
  getAvailableDomainsSuccess,
  getAvailableDomainsFailure,
  getResellersSuccess,
  getWhoisSuccess,
  getWhoisFailure,
} = domainSlice.actions;

export default domainSlice;
