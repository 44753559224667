import moment from "moment";

export const getKeyValueRecord = (rawRecord: string) => {
  const fistColon = rawRecord.indexOf(":");
  const title = rawRecord.substring(0, fistColon);
  const record = rawRecord.substring(fistColon + 1);

  return { key: title, value: record };
};

export const getExpirationDate = (rawRecords: string[]) => {
  if (!rawRecords) return;

  const expirationDateRecord = rawRecords
    .map((r) => getKeyValueRecord(r))
    .filter((r) => r.key === "Registry Expiry Date")[0]?.value;

  if (!expirationDateRecord) {
    return undefined;
  }

  return moment(expirationDateRecord).toDate();
};
