import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import domainSlice from "./reducers/domainSlice";
import domainSagas from "./sagas/domainSagas";
// @ts-ignore
import { reducer as toastrReducer } from "react-redux-toastr";
import uiSlice from "reducers/uiSlice";
import emailSlice from "reducers/emailSlice";
import emailSagas from "sagas/emailSagas";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    domain: domainSlice.reducer,
    ui: uiSlice.reducer,
    email: emailSlice.reducer,
    toastr: toastrReducer,
  },
  // @ts-ignore
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(domainSagas);
sagaMiddleware.run(emailSagas);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
