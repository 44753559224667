import Page from "components/page/Page";
import PageTitle from "components/pageTitle/PageTitle";
import PageWrapper from "components/pageWrapper/PageWrapper";
import { memo } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const TermsAndConditionsPage = () => {
  return (
    <>
      <Helmet>
        <title>Domain Findr - Terms & Conditions</title>
      </Helmet>
      <Page collapsedView={true}>
        <PageWrapper>
          <PageTitle>Terms & Conditions</PageTitle>
          <p>
            Welcome to DomainFindr! These terms and conditions outline the rules
            and regulations for using our website. By accessing and using
            DomainFindr, you accept and agree to comply with these terms. If you
            do not agree with any part of these terms, please refrain from using
            our website.
          </p>
          <p>
            <b>Use of Website</b>
          </p>
          <p>
            1.1 Eligibility: DomainFindr is available for use by individuals who
            are at least 16 years old. By using our website, you represent and
            warrant that you are at least 16 years of age.
          </p>

          <p>
            1.2 Limited License: We grant you a limited, non-exclusive, and
            non-transferable license to access and use DomainFindr solely for
            personal and non-commercial purposes. You must not modify,
            reproduce, distribute, or create derivative works based on our
            website or its content without our explicit written permission.
          </p>

          <p>
            1.3 User Conduct: When using DomainFindr, you agree to abide by all
            applicable laws and regulations. You must not engage in any activity
            that may harm, disrupt, or impair the functioning of our website or
            interfere with other users' ability to use and enjoy DomainFindr.
            Specifically, you must not use bots, scripts, or any automated means
            to access or interact with DomainFindr.
          </p>

          <p>
            <b>Third-Party Links</b>
          </p>
          <p>
            DomainFindr provides links to third-party websites where you can
            purchase domain names. These links are provided for your convenience
            and do not constitute an endorsement or recommendation by
            DomainFindr. We have no control over the content, availability, or
            practices of these third-party websites and disclaim any
            responsibility for them. Your use of third-party websites is subject
            to the terms and conditions and privacy policies of those sites.
          </p>

          <p>
            <b>Intellectual Property</b>
          </p>
          <p>
            3.1 Ownership: DomainFindr and its content, including but not
            limited to text, graphics, logos, trademarks, and software, are
            owned or licensed by DomainFindr and protected by applicable
            intellectual property laws.
          </p>
          <p>
            3.2 Restrictions: You must not use, copy, modify, or distribute any
            of the content on DomainFindr without our prior written permission.
            You may not use any trademarks, logos, or other proprietary
            information without obtaining the necessary rights or licenses.
          </p>

          <p>
            <b>Disclaimer of Warranties</b>
          </p>
          <p>
            DomainFindr is provided on an "as is" and "as available" basis
            without any warranties or representations, whether express or
            implied. We do not warrant that our website will be error-free,
            uninterrupted, or free of viruses or other harmful components. You
            use DomainFindr at your own risk.
          </p>

          <p>
            <b>Limitation of Liability</b>
          </p>
          <p>
            To the maximum extent permitted by applicable law, DomainFindr, its
            officers, directors, employees, and agents shall not be liable for
            any indirect, incidental, consequential, or punitive damages,
            including but not limited to loss of profits, data, or use, arising
            out of or in connection with your use of our website or any
            third-party websites linked to from DomainFindr.
          </p>

          <p>
            <b>Indemnification</b>
          </p>
          <p>
            You agree to indemnify and hold harmless DomainFindr, its officers,
            directors, employees, and agents from and against any claims,
            liabilities, damages, losses, or expenses, including reasonable
            attorneys' fees and costs, arising out of or in connection with your
            use of DomainFindr, violation of these terms, or infringement of any
            intellectual property or other rights.
          </p>

          <p>
            <b>Privacy Policy</b>
          </p>

          <p>
            By using DomainFindr, you acknowledge that you have read,
            understood, and agree to the terms of our Privacy Policy. Our
            Privacy Policy explains how we collect, use, and protect your
            information.
          </p>

          <p>
            <b>Modifications and Termination</b>
          </p>

          <p>
            We reserve the right to modify, suspend, or terminate DomainFindr at
            any time, without prior notice. We may also modify these terms and
            conditions from time to time. Your continued use of our website
            after such modifications constitutes your acceptance of the updated
            terms.
          </p>

          <p>
            <b>Contact Us</b>
          </p>

          <p>
            If you have any questions, concerns, or inquiries regarding these
            terms and conditions, please <Link to={"/contact"}>contact us</Link>
            .
          </p>

          <p>
            By using DomainFindr, you acknowledge that you have read,
            understood, and agreed to these terms and conditions.
          </p>
        </PageWrapper>
      </Page>
    </>
  );
};

export default memo(TermsAndConditionsPage);
