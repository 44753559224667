import GreenButton from "components/greenButton/GreenButton";
import Page from "components/page/Page";
import PageTitle from "components/pageTitle/PageTitle";
import PageWrapper from "components/pageWrapper/PageWrapper";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo, useEffect } from "react";
import { Form, Spinner, Alert } from "react-bootstrap";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import emailSlice from "reducers/emailSlice";
import { Helmet } from "react-helmet";
import { RootState } from "store";
import {
  sendContactErrorSelector,
  sendContactSuccessSelector,
  sendingContactSelector,
} from "selectors/emailSelectors";
import { useLocation } from "react-router-dom";

const StyledHeader = styled.div`
  color: gray;
  text-align: center;
  margin-bottom: 30px;
`;

const StyledLabel = styled(Form.Label)`
  color: gray;
`;

const StyledText = styled(Form.Control)<{ $hasError: boolean | undefined }>`
  border-color: ${(props) => (props.$hasError ? "red !important" : "#e8e8ef")};

  &:focus {
    border-color: #28c604;
    box-shadow: none;
  }
`;

const ContactPage = () => {
  const dispatch = useAppDispatch();

  const sendingContact = useAppSelector((state: RootState) =>
    sendingContactSelector(state)
  );

  const sendContactError = useAppSelector((state: RootState) =>
    sendContactErrorSelector(state)
  );

  const sendContactSuccess = useAppSelector((state: RootState) =>
    sendContactSuccessSelector(state)
  );

  const location = useLocation();

  useEffect(() => {
    dispatch(emailSlice.actions.clearSendContact());
  }, [dispatch, location]);

  const schema = Yup.object({
    email: Yup.string()
      .email("A valid email is required")
      .required("Email is a required field"),
    message: Yup.string().required("Message is a required field"),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: "",
      message: "",
    },
    onSubmit: (values: any) => {
      dispatch(emailSlice.actions.sendContact(values));
    },
  });

  return (
    <>
      <Helmet>
        <title>Domain Findr - Terms & Conditions</title>
      </Helmet>
      <Page collapsedView={true} maxWidth={800}>
        <PageWrapper>
          <PageTitle>Contact</PageTitle>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <StyledHeader>
              If you want to get in contact with us for any reason please use
              the form below.
            </StyledHeader>
            <Form.Group className="mb-3" controlId="email">
              <StyledLabel>Email address</StyledLabel>
              <StyledText
                $hasError={
                  formik.touched.email && formik.errors.email !== undefined
                }
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="message">
              <StyledLabel>Message</StyledLabel>
              <StyledText
                $hasError={
                  formik.touched.message && formik.errors.message !== undefined
                }
                className="form-control"
                id="message"
                as="textarea"
                value={formik.values.message}
                onChange={formik.handleChange}
              />
            </Form.Group>
            {sendContactError && (
              <Alert variant="danger">{sendContactError}</Alert>
            )}
            {sendContactSuccess && (
              <Alert variant="success">Thank you for your message</Alert>
            )}
            {!sendContactSuccess && (
              <GreenButton disabled={sendingContact}>
                {!sendingContact && <span>Send</span>}
                {sendingContact && <Spinner size="sm" />}
              </GreenButton>
            )}
          </Form>
        </PageWrapper>
      </Page>
    </>
  );
};

export default memo(ContactPage);
