import { memo } from "react";
import styled from "styled-components";
import * as SearchResultType from "types/searchResult";
import SlideDown from "react-slidedown";
import { getExpirationDate } from "utility/whoisUtility";
import DomainResult from "components/domainResult/domainResult";
import { useAppSelector } from "hooks";
import { RootState } from "store";
import { resellersSelector } from "selectors/domainSelectors";

const AnimatedSlideDown = styled(SlideDown)`
  transition-duration: 0.3s;
  transition-timing-function: linear;
  width: 100%;
`;

interface SearchResultProps {
  searchResult: SearchResultType.default;
}

const SearchResult = (props: SearchResultProps) => {
  const { searchResult } = props;

  const expirationDate = getExpirationDate(searchResult.records);

  const resellers = useAppSelector((state: RootState) =>
    resellersSelector(state)
  );

  return (
    <>
      <AnimatedSlideDown>
        <DomainResult
          resellers={resellers}
          key={searchResult.domain}
          name={searchResult.domain}
          expiresOn={expirationDate}
          records={searchResult.records}
        />
      </AnimatedSlideDown>
    </>
  );
};

export default memo(SearchResult);
