import DomainResult from "components/domainResult/domainResult";
import Page from "components/page/Page";
import PageTitle from "components/pageTitle/PageTitle";
import PageWrapper from "components/pageWrapper/PageWrapper";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo, useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import domainSlice from "reducers/domainSlice";
import {
  expiringDomainsSelector,
  getExpiringDomainsErrorSelector,
  gettingExpiringDomainsSelector,
} from "selectors/domainSelectors";
import { RootState } from "store";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  color: #28c604 !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExpiringDomainsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(domainSlice.actions.getExpiringDomains());
  }, [dispatch]);

  const gettingExpiringDomains = useAppSelector((state: RootState) =>
    gettingExpiringDomainsSelector(state)
  );

  const expiringDomains = useAppSelector((state: RootState) =>
    expiringDomainsSelector(state)
  );

  const getExpiringDomainsError = useAppSelector((state: RootState) =>
    getExpiringDomainsErrorSelector(state)
  );

  return (
    <>
      <Helmet>
        <title>Domain Findr - Expiring Domain Name Search</title>‍
        <meta
          name="description"
          content="Snap up your next domain name with our AI powered live expiring domain list."
        />
      </Helmet>
      <Page collapsedView={true}>
        <PageWrapper>
          <PageTitle>Expiring Domains</PageTitle>

          {gettingExpiringDomains && (
            <SpinnerWrapper>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </SpinnerWrapper>
          )}

          {getExpiringDomainsError && (
            <Alert variant="danger">{getExpiringDomainsError}</Alert>
          )}

          {expiringDomains &&
            expiringDomains.map((expiringDomain, index) => {
              const name = expiringDomain.name + "." + expiringDomain.suffix;
              return (
                <DomainResult
                  resellers={undefined}
                  key={name}
                  name={name}
                  expiresOn={expiringDomain.expiresOn}
                  drawSpacer={index !== expiringDomains.length - 1}
                />
              );
            })}
        </PageWrapper>
      </Page>
    </>
  );
};

export default memo(ExpiringDomainsPage);
