import axios from "axios";
import { Outlet } from "react-router-dom";
import "./App.css";
import Footer from "components/footer/Footer";
import Navbar from "components/navbar/Navbar";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { RootState } from "store";
import domainSlice from "reducers/domainSlice";
import { resellersSelector } from "selectors/domainSelectors";

axios.defaults.baseURL = process.env["REACT_APP_API_BASE_URL"];

const SetUpAxiosInterceptors = () => {
  axios.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      if (error.response && error.response.status === 400) {
        let errorMessages = [];
        for (const property in error.response.data.errors) {
          errorMessages.push(error.response.data.errors[property][0]);
        }
        if (errorMessages.length > 0)
          error.message = errorMessages.join("\r\n");
        else if (error.response.data.detail)
          error.message = error.response.data.detail;
      }
      return Promise.reject(error);
    }
  );
};

function App() {
  SetUpAxiosInterceptors();

  const dispatch = useAppDispatch();

  const resellers = useAppSelector((state: RootState) =>
    resellersSelector(state)
  );

  useEffect(() => {
    if (!resellers) {
      dispatch(domainSlice.actions.getResellers());
    }
  }, [resellers, dispatch]);

  return (
    <div className="App">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
export default App;
