import { ReactNode, memo } from "react";
import styled from "styled-components";

const StyledH1 = styled.div`
  border: solid 1px #e8e8ef;
  background: white;
  width: 100%;
  padding: 10px;
  display: flex;
  max-width: 1600px;
  flex-direction: column;
  padding: 30px 50px;
  margin-bottom: 20px;
  border-radius: 10px;

  @media only screen and (max-width: 576px) {
    padding: 15px 25px;
    margin-bottom: 0 !important;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  @media only screen and (max-width: 992px) {
    padding: 10px 5px;
    margin-bottom: 15px;
  }

  a {
    text-decoration: none;
    color: #28c604;

    &:hover {
      text-decoration: underline;
    }
  }
`;

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper = (props: PageWrapperProps) => {
  const { children } = props;

  return <StyledH1>{children}</StyledH1>;
};

export default memo(PageWrapper);
