import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ContactRequest from "types/contactRequest";

interface EmailState {
  sendingContact: boolean;
  sendContactSuccess: boolean;
  sendContactError: string | undefined;
}

const initialState: EmailState = {
  sendingContact: false,
  sendContactSuccess: false,
  sendContactError: undefined,
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    clearSendContact: (state) => {
      state.sendContactSuccess = false;
      state.sendContactError = undefined;
      state.sendingContact = false;
    },
    sendContact: (state, action: PayloadAction<ContactRequest>) => {
      state.sendContactSuccess = false;
      state.sendContactError = undefined;
      state.sendingContact = true;
    },
    sendContactSuccess: (state) => {
      state.sendContactSuccess = true;
      state.sendingContact = false;
    },
    sendContactFailure: (state, action: PayloadAction<string>) => {
      state.sendContactSuccess = false;
      state.sendingContact = false;
      state.sendContactError = action.payload;
    },
  },
});

export const { sendContact, sendContactSuccess, sendContactFailure } =
  emailSlice.actions;

export default emailSlice;
