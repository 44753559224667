import DomainResult from "components/domainResult/domainResult";
import Page from "components/page/Page";
import PageTitle from "components/pageTitle/PageTitle";
import PageWrapper from "components/pageWrapper/PageWrapper";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo, useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import domainSlice from "reducers/domainSlice";
import {
  gettingAvailableDomainsSelector,
  availableDomainsSelector,
  getAvailableDomainsErrorSelector,
  resellersSelector,
} from "selectors/domainSelectors";
import { RootState } from "store";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  color: #28c604 !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvailableDomainsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(domainSlice.actions.getAvailableDomains());
  }, [dispatch]);

  const gettingAvailableDomains = useAppSelector((state: RootState) =>
    gettingAvailableDomainsSelector(state)
  );

  const availableDomains = useAppSelector((state: RootState) =>
    availableDomainsSelector(state)
  );

  const getAvailableDomainsError = useAppSelector((state: RootState) =>
    getAvailableDomainsErrorSelector(state)
  );

  const resellers = useAppSelector((state: RootState) =>
    resellersSelector(state)
  );

  return (
    <>
      <Helmet>
        <title>Domain Findr - Available Domain Name Search</title>‍
        <meta
          name="description"
          content="Find your next domain name with our live available domain list powered by AI."
        />
      </Helmet>
      <Page collapsedView={true}>
        <PageWrapper>
          <PageTitle>Available Domains</PageTitle>

          {gettingAvailableDomains && (
            <SpinnerWrapper>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </SpinnerWrapper>
          )}

          {getAvailableDomainsError && (
            <Alert variant="danger">{getAvailableDomainsError}</Alert>
          )}

          {availableDomains &&
            availableDomains.map((availableDomain, index) => {
              const name = availableDomain.name + "." + availableDomain.suffix;
              return (
                <DomainResult
                  resellers={resellers}
                  key={name}
                  name={name}
                  expiresOn={availableDomain.expiresOn}
                  drawSpacer={index !== availableDomains.length - 1}
                />
              );
            })}
        </PageWrapper>
      </Page>
    </>
  );
};

export default memo(AvailableDomainsPage);
