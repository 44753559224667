import Alternatives from "components/alternatives/Alternatives";
import DomainResult from "components/searchResult/searchResult";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo, useEffect } from "react";
import domainSlice from "reducers/domainSlice";
import {
  alternativeDomainExtensionsGottenForDomainSelector,
  alternativeDomainExtensionsSelector,
  gettingAlternativeDomainExtensionsSelector,
} from "selectors/domainSelectors";
import { RootState } from "store";
import styled from "styled-components";
import SearchResult from "types/searchResult";

const Wrapper = styled.div`
  border: solid 1px #e8e8ef;
  border-radius: 10px;
  background: white;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 20px;

  @media only screen and (max-width: 576px) {
    margin-bottom: 0 !important;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
`;

const Title = styled.div`
  border-top: 1px solid #e8e8ef;
  border-bottom: 1px solid #e8e8ef;
  text-align: center;
  font-size: 1rem;
  padding: 10px 5px;
`;

interface SearchResultsProps {
  searchResult: SearchResult | undefined;
}

const SearchResults = (props: SearchResultsProps) => {
  const dispatch = useAppDispatch();

  const { searchResult } = props;

  const gettingAlternativeDomainExtensions = useAppSelector(
    (state: RootState) => gettingAlternativeDomainExtensionsSelector(state)
  );

  const alternativeDomainExtensionsGottenForDomain = useAppSelector(
    (state: RootState) =>
      alternativeDomainExtensionsGottenForDomainSelector(state)
  );

  const alternativeDomainExtensions = useAppSelector((state: RootState) =>
    alternativeDomainExtensionsSelector(state)
  );

  useEffect(() => {
    if (
      searchResult &&
      searchResult.registered &&
      alternativeDomainExtensionsGottenForDomain !== searchResult.domain
    ) {
      dispatch(
        domainSlice.actions.getAlternativeDomainExtensions(searchResult.domain)
      );
    }
  }, [
    alternativeDomainExtensionsGottenForDomain,
    dispatch,
    gettingAlternativeDomainExtensions,
    searchResult,
  ]);

  if (!searchResult) return null;

  return (
    <Wrapper>
      <DomainResult key="firstResult" searchResult={searchResult} />
      {searchResult.registered && (
        <>
          <Title>
            Sorry {searchResult.domain} is taken but here are some alternatives
          </Title>
          <Alternatives alternatives={alternativeDomainExtensions} />
        </>
      )}
    </Wrapper>
  );
};

export default memo(SearchResults);
