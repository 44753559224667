import { RootState } from "../store";

export const searchResultSelector = (state: RootState) =>
  state.domain.searchResult;

export const domainSearchingSelector = (state: RootState) =>
  state.domain.searchingDomain;

export const gettingAlternativeDomainExtensionsSelector = (state: RootState) =>
  state.domain.gettingAlternativeDomainExtensions;

export const alternativeDomainExtensionsGottenForDomainSelector = (
  state: RootState
) => state.domain.alternativeDomainExtensionsGottenForDomain;

export const alternativeDomainExtensionsSelector = (state: RootState) =>
  state.domain.alternativeDomainExtensions;

export const expiringDomainsSelector = (state: RootState) =>
  state.domain.expiringDomains;

export const gettingExpiringDomainsSelector = (state: RootState) =>
  state.domain.gettingExpiringDomains;

export const getExpiringDomainsErrorSelector = (state: RootState) =>
  state.domain.getExpiringDomainsError;

export const availableDomainsSelector = (state: RootState) =>
  state.domain.availableDomains;

export const gettingAvailableDomainsSelector = (state: RootState) =>
  state.domain.gettingAvailableDomains;

export const getAvailableDomainsErrorSelector = (state: RootState) =>
  state.domain.getAvailableDomainsError;

export const resellersSelector = (state: RootState) => state.domain.resellers;

export const whoisSelector = (state: RootState) => state.domain.whois;

export const gettingWhoisSelector = (state: RootState) =>
  state.domain.gettingWhois;
