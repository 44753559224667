import { memo } from "react";
import styled from "styled-components";
import { Modal, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { getKeyValueRecord } from "utility/whoisUtility";

interface WhoisModalProps {
  visible: boolean;
  onClose: () => void;
  name: string;
  records: Array<string> | undefined;
  recordsLoading?: boolean | undefined;
}

const RecordLine = styled.div`
  padding: 10px 20px;
  word-wrap: break-word;
  width: 100%;

  > b {
    display: block;
  }
`;

const StyledHeader = styled(Modal.Header)`
  font-size: 1.2rem;
  display: flex;
  padding: 20px 20px;
  border: 0;

  button {
    opacity: 1;
    box-shadow: none !important;
    width: 15px;
    display: block;
    padding: 0 !important;
    margin: -10px 0 0 0 !important;
  }
`;

const StyledBody = styled(Modal.Body)`
  padding: 0;
  min-height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;

  > div:nth-child(odd) {
    background: #f4f5f7;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 1.1rem;
  margin-left: 10px;
  flex: 1;
`;

const StyledModal = styled(Modal)`
  div.modal-content {
    overflow: hidden;
  }
`;

const SpinnerWrapper = styled.div`
  color: #28c604 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white !important;
  flex: 1;
`;

const None = styled.div`
  text-align: center;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background: white !important;
`;

const WhoisModal = (props: WhoisModalProps) => {
  const { name, records, visible, onClose, recordsLoading = false } = props;

  return (
    <StyledModal
      show={visible}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <StyledHeader closeButton>
        <FontAwesomeIcon icon={faGlobe} />
        <Title>Whois Results For {name}</Title>
      </StyledHeader>
      <StyledBody>
        {recordsLoading && (
          <SpinnerWrapper>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </SpinnerWrapper>
        )}
        {!recordsLoading &&
          records &&
          records.map((r, i) => {
            const keyValueRecord = getKeyValueRecord(r);
            return (
              <RecordLine key={`${i}${keyValueRecord.key}`}>
                <b>{keyValueRecord.key}</b>
                <span>{keyValueRecord.value}</span>
              </RecordLine>
            );
          })}
        {!recordsLoading && !records && <None>No Records Returned</None>}
      </StyledBody>
    </StyledModal>
  );
};

export default memo(WhoisModal);
