import Page from "components/page/Page";
import PageTitle from "components/pageTitle/PageTitle";
import PageWrapper from "components/pageWrapper/PageWrapper";
import { memo } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  document.title = "Domain Findr - Privacy Policy";

  return (
    <>
      <Helmet>
        <title>Domain Findr - Privacy Policy</title>
      </Helmet>
      <Page collapsedView={true}>
        <PageWrapper>
          <PageTitle>Privacy Policy</PageTitle>
          <p>
            At DomainFindr, we are committed to protecting your privacy and
            safeguarding your personal information. This privacy policy explains
            how we collect, use, and handle any information that you provide
            while using our website. Please read this policy carefully to
            understand our practices regarding your data.
          </p>
          <p>
            <b>Information Collection</b>
          </p>
          <p>
            1.1 Search Queries: When you use DomainFindr to search for domain
            name availability, we do not collect or store any personal
            information related to your search queries. We do not track the
            specific domain names you search for or link them to your identity.
          </p>

          <p>
            1.2 Third-Party Links: DomainFindr provides links to third-party
            websites where you can purchase domain names. When you click on
            these links, you will be directed to external sites that have their
            own privacy policies. We encourage you to review the privacy
            policies of these third-party websites before providing any personal
            information.
          </p>

          <p>
            <b>Google Analytics and Ads</b>
          </p>
          <p>
            2.1 Data Collection: DomainFindr utilizes Google Analytics and
            Google Ads to gather insights into website traffic and user
            behavior. These services automatically collect certain non-personal
            information about your interactions with our site, such as your IP
            address, browser type, operating system, referring website, pages
            visited, and the date and time of your visit. This data is collected
            and processed by Google Analytics and Google Ads on our behalf.
          </p>

          <p>
            2.2 Use of Collected Data: The information collected by Google
            Analytics and Google Ads is used to analyze website performance,
            improve our services, and display relevant advertisements. It helps
            us understand user preferences, track the effectiveness of marketing
            campaigns, and optimize our website's content and functionality. The
            data collected by Google Analytics and Google Ads is aggregated and
            does not personally identify you.
          </p>

          <p>
            2.3 Google's Privacy Policies: Google Analytics and Google Ads are
            third-party services, and their data collection and processing are
            subject to their respective privacy policies. We recommend reviewing
            Google's Privacy Policy (Privacy Policy – Privacy & Terms – Google )
            to understand how they handle your information.
          </p>

          <p>
            <b>Information Security</b>
          </p>
          <p>
            We take reasonable measures to protect the security and integrity of
            your personal information. However, please be aware that no data
            transmission over the internet or electronic storage method can be
            guaranteed to be 100% secure. While we strive to use commercially
            acceptable means to protect your data, we cannot guarantee absolute
            security.
          </p>

          <p>
            <b>Third-Party Websites</b>
          </p>
          <p>
            DomainFindr provides links to third-party websites for purchasing
            domain names. These websites have their own privacy policies and
            practices, which may differ from ours. We are not responsible for
            the privacy practices or content of these third-party websites. We
            encourage you to review their privacy policies before providing any
            personal information.
          </p>

          <p>
            <b>Children's Privacy</b>
          </p>
          <p>
            DomainFindr is not intended for individuals under the age of 16. We
            do not knowingly collect or store personal information from
            children. If you believe that we may have inadvertently collected
            personal information from a child, please contact us immediately,
            and we will take steps to delete the information promptly.
          </p>

          <p>
            <b>Changes to This Privacy Policy</b>
          </p>
          <p>
            We reserve the right to update or modify this privacy policy at any
            time, without prior notice. Any changes made will be effective
            immediately upon posting the updated policy on the DomainFindr
            website. We encourage you to review this policy periodically to stay
            informed about how we protect your information.
          </p>

          <p>
            <b>Contact Us</b>
          </p>
          <p>
            If you have any questions, concerns, or requests regarding this
            privacy policy or the way we handle your information, please{" "}
            <Link to={"/contact"}>contact us</Link>.
          </p>

          <p>
            By using DomainFindr, you acknowledge that you have read and
            understood this privacy policy and agree to its terms.
          </p>
        </PageWrapper>
      </Page>
    </>
  );
};

export default memo(PrivacyPolicyPage);
