import Spacer from "components/spacer/Spacer";
import { memo } from "react";
import { Container, Nav, Navbar as ReactNavbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledNavbar = styled(ReactNavbar)`
  background: #28c604;
  padding: 0;
`;

const StyledNav = styled(ReactNavbar.Collapse)`
  display: flex;
  @media only screen and (min-width: 992px) {
    justify-content: center !important;
  }
`;

const StyledNavbarToggle = styled(ReactNavbar.Toggle)`
  margin-left: auto;
  border: 0 !important;
  box-shadow: none !important;

  > span {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  }
`;

const StyledLink = styled(Link)`
  color: white;
  padding: 5px 10px;
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledContainer = styled(Container)`
  padding: 0;
`;

const Navbar = () => {
  return (
    <StyledNavbar expand="lg" data-bs-theme="dark">
      <StyledContainer fluid={true}>
        <StyledNavbarToggle aria-controls="responsive-navbar-nav"></StyledNavbarToggle>
        <StyledNav id="responsive-navbar-nav">
          <Nav className="d-flex">
            <StyledLink to={"/"}>Search Domains</StyledLink>
            <Spacer color="white" />
            <StyledLink to={"/expiring-domains"}>Expiring Domains</StyledLink>
            <Spacer color="white" />
            <StyledLink to={"/available-domains"}>Available Domains</StyledLink>
          </Nav>
        </StyledNav>
      </StyledContainer>
    </StyledNavbar>
  );
};

export default memo(Navbar);
