// @ts-ignore
import { toastr } from "react-redux-toastr";

import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import domainSlice, {
  getAlternativeDomainExtensionsFailure,
  getAlternativeDomainExtensionsSuccess,
  searchAlternativeDomainExtensionFailure,
  searchAlternativeDomainExtensionSuccess,
  searchDomainFailure,
  searchDomainSuccess,
  getExpiringDomainsSuccess,
  getExpiringDomainsFailure,
  getAvailableDomainsSuccess,
  getAvailableDomainsFailure,
  getResellersSuccess,
  getWhoisSuccess,
  getWhoisFailure,
} from "reducers/domainSlice";
import HttpResponse from "types/httpResponse";
import SearchResult from "types/searchResult";
import GetAlternativesResponse from "types/getAlternativesResponse";
import GetExpiringDomainsResponse from "types/getExpiringDomainsResponse";
import GetAvailableDomainsResponse from "types/getAvailableDomainsResponse";
import GetResellersResponse from "types/getResllersResponse";

function* searchDomainSaga(action: PayloadAction<string>) {
  try {
    if (action.payload.length === 0) {
      yield put(searchDomainSuccess(undefined));
      return;
    }

    const response: HttpResponse<SearchResult> = yield axios.get(
      `/domain/whois/${encodeURIComponent(action.payload)}`
    );
    yield put(searchDomainSuccess(response.data));
  } catch (error: any) {
    toastr.error("Error Searching For Domain", error.message);
    yield put(searchDomainFailure());
  }
}

function* getWhoisSaga(action: PayloadAction<string>) {
  try {
    if (action.payload.length === 0) {
      yield put(searchDomainSuccess(undefined));
      return;
    }

    const response: HttpResponse<SearchResult> = yield axios.get(
      `/domain/whois/${encodeURIComponent(action.payload)}`
    );
    yield put(getWhoisSuccess(response.data));
  } catch (error: any) {
    yield put(getWhoisFailure(error.me));
  }
}

function* searchAlternativeDomainExtensionSaga(action: PayloadAction<string>) {
  try {
    if (action.payload.length === 0) {
      throw new Error("Empty payload");
    }

    const response: HttpResponse<SearchResult> = yield axios.get(
      `/domain/whois/${encodeURIComponent(action.payload)}`
    );

    yield put(searchAlternativeDomainExtensionSuccess(response.data));
  } catch (error: any) {
    toastr.error(
      "Error Searching For Alternative Domain Extension",
      error.message
    );
    yield put(searchAlternativeDomainExtensionFailure(action.payload));
  }
}

function* getAlternativeDomainExtensionsSaga(action: PayloadAction<string>) {
  try {
    const response: HttpResponse<GetAlternativesResponse> = yield axios.get(
      `/domain/alternativedomainextensions/${encodeURIComponent(
        action.payload
      )}`
    );
    yield put(getAlternativeDomainExtensionsSuccess(response.data));
  } catch (error: any) {
    toastr.error("Error Getting Alternative Domain Extensions", error.message);
    yield put(getAlternativeDomainExtensionsFailure());
  }
}

function* getExpiringDomainsSaga() {
  try {
    const response: HttpResponse<GetExpiringDomainsResponse> = yield axios.get(
      "/domain/expiringdomainnames"
    );
    yield put(getExpiringDomainsSuccess(response.data));
  } catch (error: any) {
    yield put(getExpiringDomainsFailure(error.message));
  }
}

function* getAvailableDomainsSaga() {
  try {
    const response: HttpResponse<GetAvailableDomainsResponse> = yield axios.get(
      "/domain/availabledomainnames"
    );
    yield put(getAvailableDomainsSuccess(response.data));
  } catch (error: any) {
    yield put(getAvailableDomainsFailure(error.message));
  }
}

function* getResellersSaga() {
  try {
    const response: HttpResponse<GetResellersResponse> = yield axios.get(
      "/reseller"
    );
    yield put(getResellersSuccess(response.data));
  } catch (error: any) {
    toastr.error("Error Getting Resellers", error.message);
  }
}

function* domainSaga() {
  yield takeLatest(domainSlice.actions.searchDomain, searchDomainSaga);
  yield takeLatest(domainSlice.actions.getWhois, getWhoisSaga);
  yield takeEvery(
    domainSlice.actions.searchAlternativeDomainExtension,
    searchAlternativeDomainExtensionSaga
  );
  yield takeLatest(
    domainSlice.actions.getAlternativeDomainExtensions,
    getAlternativeDomainExtensionsSaga
  );
  yield takeLatest(
    domainSlice.actions.getExpiringDomains,
    getExpiringDomainsSaga
  );
  yield takeLatest(
    domainSlice.actions.getAvailableDomains,
    getAvailableDomainsSaga
  );
  yield takeLatest(domainSlice.actions.getResellers, getResellersSaga);
}

export default domainSaga;
