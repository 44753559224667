import moment from "moment";
import { memo } from "react";
import styled from "styled-components";

const Expired = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  background: ${(props) => props.$backgroundColor ?? "#ae0010"};
  color: white;
  font-size: 0.8rem;
  margin-left: 10px;
  border-radius: 10px;
  align-items: center;
  padding: 0px 7px 1px;
`;

const Expiring = styled(Expired)`
  background: #e1821d;
`;

const ExpiresThresholdDays = 90;

interface ExpireBadgeProps {
  expirationDate?: Date;
  collapsedView: boolean;
}

const ExpireBadge = (props: ExpireBadgeProps) => {
  const { expirationDate, collapsedView } = props;

  let daysTillExpiration = undefined;

  if (!expirationDate) {
    return null;
  }

  if (expirationDate) {
    daysTillExpiration = moment(expirationDate).diff(new Date(), "days") + 1;
  }

  if (daysTillExpiration && daysTillExpiration < 0) {
    return (
      <Expired>
        {!collapsedView
          ? `Expired ${-1 * daysTillExpiration} days ago`
          : "expired"}
      </Expired>
    );
  }

  if (
    daysTillExpiration &&
    daysTillExpiration < ExpiresThresholdDays &&
    daysTillExpiration > 0
  ) {
    return (
      <Expiring>
        {!collapsedView ? `Expires in ${daysTillExpiration} days` : "expiring"}
      </Expiring>
    );
  }

  if (daysTillExpiration === 0) {
    return <Expiring>{!collapsedView ? `Expires today` : "expiring"}</Expiring>;
  }

  return null;
};

export default memo(ExpireBadge);
