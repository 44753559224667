import { FC, ReactNode, memo } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styled from "styled-components";

interface GreenButtonProps {
  children: ReactNode;
  disabled: boolean;
}

const StyledButton: FC<ButtonProps> = styled(Button)`
  background-color: #28c604;
  border-color: #28c604;

  &:focus,
  &:active,
  &:disabled,
  &:hover {
    border-color: #28c604 !important;
    background-color: #28c604 !important;
  }
`;

const GreenButton = (props: GreenButtonProps) => {
  const { children, disabled } = props;

  return (
    <StyledButton disabled={disabled} type="submit">
      {children}
    </StyledButton>
  );
};

export default memo(GreenButton);
