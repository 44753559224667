import { memo } from "react";
import styled from "styled-components";

const StyledSpacer = styled.div<{ color: string }>`
  width: 1px;
  background: ${(props: any) => props.color};
  margin: 10px 0;
`;

interface SpacerProps {
  color: string;
}

const Spacer = (props: SpacerProps) => {
  const { color } = props;

  return <StyledSpacer color={color} />;
};

export default memo(Spacer);
