import { ReactNode, memo } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const OuterWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Container)<{
  $collapsedView: boolean;
  $maxWidth: number;
}>`
  max-width: ${(props: any) => props.$maxWidth}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${(props: any) =>
    props.$collapsedView ? "start" : "center"};
  align-items: center;

  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

const Logo = styled.img<{ $collapsedView: boolean }>`
  height: ${(props: any) => (props.$collapsedView ? "30px" : "45px")};
  margin-bottom: ${(props: any) => (props.$collapsedView ? "20px" : "50px")};
  margin-top: ${(props: any) => (props.$collapsedView ? "20px" : "0px")};
  @media only screen and (max-width: 600px) {
    height: 30px;
  }
`;

interface PageProps {
  collapsedView: boolean;
  children: ReactNode;
  maxWidth?: number | undefined;
}

const Page = (props: PageProps) => {
  const { collapsedView, children, maxWidth } = props;

  return (
    <OuterWrapper>
      <Wrapper $maxWidth={maxWidth ?? 1200} $collapsedView={collapsedView}>
        <Logo $collapsedView={collapsedView} alt="logo" src="logo.png" />
        {children}
      </Wrapper>
    </OuterWrapper>
  );
};

export default memo(Page);
