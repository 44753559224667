import SearchResults from "components/searchResults/SearchResults";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo, useState } from "react";
import domainSlice from "reducers/domainSlice";
import {
  domainSearchingSelector,
  searchResultSelector,
} from "selectors/domainSelectors";
import { RootState } from "store";
import SearchPanel from "../../components/searchPanel/SearchPanel";
import Page from "components/page/Page";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const dispatch = useAppDispatch();

  const [hasSearchPhrase, setHasSearchPhrase] = useState(false);

  const searchResult = useAppSelector((state: RootState) =>
    searchResultSelector(state)
  );

  const domainSearching = useAppSelector((state: RootState) =>
    domainSearchingSelector(state)
  );

  const handleSearchClick = (phrase: string) => {
    setHasSearchPhrase(phrase.length > 0);
    dispatch(domainSlice.actions.searchDomain(phrase));
  };

  return (
    <>
      <Helmet>
        <title>Domain Findr - AI Powered Domain Name Search</title>‍
        <meta
          name="description"
          content="Our AI powered platform will allow you to search through expiring, for sale and unregistered domains quickly and effectively."
        />
      </Helmet>
      <Page collapsedView={hasSearchPhrase}>
        <SearchPanel onSearch={handleSearchClick} searching={domainSearching} />
        {hasSearchPhrase && <SearchResults searchResult={searchResult} />}
      </Page>
    </>
  );
};

export default memo(HomePage);
