import { ReactNode, memo } from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import DropDownItem from "types/dropDownItem";

interface DropDownButtonProps {
  children: ReactNode;
  dropDownItems: Array<DropDownItem>;
}

const StyledDropDownToggle = styled(Dropdown.Toggle)`
  background-color: #21b400 !important;
  border-color: #21b400 !important;

  &:focus,
  &:active,
  &:disabled,
  &:hover {
    border-color: #21b400 !important;
    background-color: #21b400 !important;
  }
`;

const StyledDropDownItem = styled(Dropdown.Item)`
  color: #212529 !important;
  text-decoration: none !important;
`;

const StyledDropDownMenu = styled(Dropdown.Menu)`
  padding: 3px 0;
`;

const DropDownButton = (props: DropDownButtonProps) => {
  const { children, dropDownItems } = props;

  return (
    <Dropdown>
      <StyledDropDownToggle variant="success">{children}</StyledDropDownToggle>

      <StyledDropDownMenu>
        {dropDownItems.map((value: DropDownItem) => {
          return (
            <StyledDropDownItem target="_blank" href={value.url}>
              {value.title}
            </StyledDropDownItem>
          );
        })}
      </StyledDropDownMenu>
    </Dropdown>
  );
};

export default memo(DropDownButton);
