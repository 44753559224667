import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowWidth } from "@react-hook/window-size";
import DropDownButton from "components/dropDownButton/DropDownButton";
import ExpireBadge from "components/expireBadge/ExpireBadge";
import { useAppDispatch, useAppSelector } from "hooks";
import WhoisModal from "modals/whoisModal/whoisModal";
import moment from "moment";
import { memo, useState } from "react";
import { Spinner } from "react-bootstrap";
import domainSlice from "reducers/domainSlice";
import uiSlice from "reducers/uiSlice";
import { gettingWhoisSelector, whoisSelector } from "selectors/domainSelectors";
import { RootState } from "store";
import styled from "styled-components";

const Wrapper = styled.div<{ $hasBorder: boolean; $registered: boolean }>`
  border-bottom: ${(props: any) =>
    props.$hasBorder ? "solid 1px #e8e8ef" : null};
  display: flex;
  padding: 17px 22px;
  align-items: center;
  > svg {
    color: ${(props: any) => (props.$registered ? "#c60404" : "#28c604")};
    margin-top: 4px;
    margin-right: 10px;
  }

  @media only screen and (max-width: 576px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const StyledDomain = styled.div<{ $registered: boolean; $loaded: boolean }>`
  font-weight: 500;
  flex: 1;
  display: flex;
  color: ${(props: any) =>
    props.$loaded ? (props.$registered ? "#c60404" : "#21b400") : "#222222"};
`;

const SpinnerWrapper = styled.div`
  color: #28c604;
  margin-right: 10px;
`;

const Whois = styled.div`
  display: flex;
  background: #5e5e5e;
  color: white;
  font-size: 0.8rem;
  margin-left: 10px;
  border-radius: 15px;
  align-items: center;
  padding: 5px 14px 6px;
  cursor: pointer;
  &:hover {
    background: #3a3a3a;
  }
`;

interface DomainResultProps {
  drawSpacer?: boolean;
  resellers?: Array<string> | undefined;
  loading?: boolean;
  expiresOn: string | Date | undefined;
  name: string;
  records?: Array<string> | undefined;
}

const DomainResult = (props: DomainResultProps) => {
  const {
    name,
    expiresOn,
    drawSpacer = false,
    loading = false,
    records,
    resellers,
  } = props;

  const dispatch = useAppDispatch();

  const [whoisVisible, setWhoisVisible] = useState(false);

  const onlyWidth = useWindowWidth();

  const expirationDate = expiresOn ? moment(expiresOn).toDate() : undefined;

  const registered = expirationDate !== undefined;

  const whois = useAppSelector((state: RootState) => whoisSelector(state));

  const gettingWhois = useAppSelector((state: RootState) =>
    gettingWhoisSelector(state)
  );

  const handleWhoisClick = () => {
    if (records === undefined) {
      dispatch(domainSlice.actions.getWhois(name));
    }
    dispatch(uiSlice.actions.showWhoisModal());
    setWhoisVisible(true);
  };

  const handleWhoisCloseClick = () => {
    dispatch(uiSlice.actions.hideWhoisModal());
    setWhoisVisible(false);
  };

  const dropDownItems = resellers?.map((value) => {
    return {
      url: `${process.env["REACT_APP_API_BASE_URL"]}/domain/buy/${value}/${name}`,
      title: value,
    };
  });

  return (
    <>
      <Wrapper $hasBorder={drawSpacer} $registered={registered}>
        {loading === true && (
          <SpinnerWrapper>
            <Spinner size="sm" />
          </SpinnerWrapper>
        )}
        {loading === false && (
          <FontAwesomeIcon icon={registered ? faXmark : faCheck} />
        )}
        <StyledDomain
          $registered={registered}
          $loaded={loading === false && registered !== undefined}
        >
          {name}
        </StyledDomain>
        <ExpireBadge
          expirationDate={expiresOn ? moment(expiresOn).toDate() : undefined}
          collapsedView={onlyWidth <= 380}
        />
        {registered && <Whois onClick={handleWhoisClick}>Whois</Whois>}
        {!registered && dropDownItems && (
          <DropDownButton dropDownItems={dropDownItems}>Buy</DropDownButton>
        )}
      </Wrapper>
      {whoisVisible && (
        <WhoisModal
          name={name}
          recordsLoading={records !== undefined ? false : gettingWhois}
          records={records ?? whois?.records}
          visible={true}
          onClose={handleWhoisCloseClick}
        />
      )}
    </>
  );
};

export default memo(DomainResult);
