import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import ContactRequest from "types/contactRequest";
import emailSlice, {
  sendContactFailure,
  sendContactSuccess,
} from "reducers/emailSlice";

function* sendContactSaga(action: PayloadAction<ContactRequest>) {
  try {
    yield axios.post(`/email/sendcontact`, action.payload);
    yield put(sendContactSuccess());
  } catch (error: any) {
    yield put(sendContactFailure(error.message));
  }
}

function* emailSaga() {
  yield takeLatest(emailSlice.actions.sendContact, sendContactSaga);
}

export default emailSaga;
