import DomainResult from "components/searchResult/searchResult";
import { useAppDispatch } from "hooks";
import { memo, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import domainSlice from "reducers/domainSlice";
import styled from "styled-components";
import SearchResult from "types/searchResult";

interface AlternativesProps {
  alternatives: Array<SearchResult> | undefined;
}

const SpinnerWrapper = styled.div`
  color: #6ba1f1;
  text-align: center;
  padding: 10px 0;
`;

const Wrapper = styled.div``;

const Alternatives = (props: AlternativesProps) => {
  const dispatch = useAppDispatch();

  const { alternatives } = props;

  useEffect(() => {
    alternatives?.forEach((a) => {
      if (!a.loading && a.registered === undefined) {
        dispatch(
          domainSlice.actions.searchAlternativeDomainExtension(a.domain)
        );
      }
    });
  }, [alternatives, dispatch]);

  return (
    <Wrapper>
      {!alternatives && (
        <SpinnerWrapper>
          <Spinner
            as="span"
            animation="border"
            role="status"
            size="sm"
            aria-hidden="true"
          />
        </SpinnerWrapper>
      )}
      {alternatives &&
        alternatives.map((a) => {
          return <DomainResult key={a.domain} searchResult={a} />;
        })}
    </Wrapper>
  );
};

export default memo(Alternatives);
