import { memo } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";

const Wrapper = styled.div`
  border: solid 1px #e8e8ef;
  border-radius: 10px;
  background: white;
  width: 100%;
  padding: 10px;
  display: flex;
  max-width: 500px;
`;

const SpinnerWrapper = styled.div`
  color: #28c604 !important;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const SearchInput = styled(DebounceInput)<{
  className: string;
  spellCheck: string;
}>`
  border: none !important;
  box-shadow: none !important;
`;

interface SearchPanelProps {
  onSearch: (phrase: string) => void;
  searching: boolean;
}

const SearchPanel = (props: SearchPanelProps) => {
  const { onSearch, searching } = props;

  const handleSearchChange = (e: any) => {
    onSearch(e.target.value);
  };

  return (
    <Wrapper>
      <SearchInput
        type="text"
        placeholder="awesome.com"
        minLength={2}
        debounceTimeout={500}
        onChange={handleSearchChange}
        className="form-control"
        spellCheck="false"
      />
      {searching && (
        <SpinnerWrapper>
          <Spinner
            as="span"
            animation="border"
            role="status"
            size="sm"
            aria-hidden="true"
            color="#6ba1f1"
          />
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
};

export default memo(SearchPanel);
