import { ReactNode, memo } from "react";
import styled from "styled-components";

const StyledH1 = styled.h1`
  margin-bottom: 10px;
  text-align: center;
  font-size: 1.6rem;

  @media only screen and (min-width: 992px) {
    font-size: 2rem;
    margin-bottom: 30px;
  }
`;

interface PageTitleProps {
  children: ReactNode;
}

const PageTitle = (props: PageTitleProps) => {
  const { children } = props;

  return <StyledH1>{children}</StyledH1>;
};

export default memo(PageTitle);
