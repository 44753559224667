import { createSlice } from "@reduxjs/toolkit";

interface DomainState {
  whoisModalVisible: boolean;
}

const initialState: DomainState = {
  whoisModalVisible: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showWhoisModal: (state) => {
      state.whoisModalVisible = true;
    },
    hideWhoisModal: (state) => {
      state.whoisModalVisible = false;
    },
  },
});

export const { hideWhoisModal } = uiSlice.actions;

export default uiSlice;
